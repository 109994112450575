<template>
  <v-dialog v-model="showErrorMessage" max-width="300px" class="py-3" persistent>
    <v-card v-show="showErrorMessage">
      <v-card-title class="py-2 text-h4" :class="color">
        {{ title }}
      </v-card-title>

      <v-card-text v-for="(item, index) in error" :key="index" class="text-center pt-5">
        <!-- {{ item }} -->
        <p v-html="item" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn :class="color" small @click="showErrorMessage = false"> OK </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatErrorMessages } from '@/utils/utils.js'
import cnst from '@/const/const'

export default {
  name: 'ErrorMessage',
  props: {},
  computed: {
    showErrorMessage: {
      get() {
        return this.$store.state.error.showErrorMessage
      },
      set(value) {
        this.$store.commit(cnst.SHOW_ERROR, value)
        if (value === false && this.$store.state.error.errorCallback !== null) {
          this.$store.state.error.errorCallback()
          this.$store.commit(cnst.ERROR_CALLBACK, null, { root: true })
        }
      },
    },
    error() {
      return formatErrorMessages('errors', this.$store.state.error.errorMessage)
    },
    title() {
      return this.$store.state.error.errorMessage === cnst.MSG_UPDATE_NEW_VERSION ? 'バージョンチェック' : 'ERROR'
    },
    color() {
      return this.$store.state.error.errorMessage === cnst.MSG_UPDATE_NEW_VERSION ? 'light-blue white--text' : 'error lighten-2'
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        this.showErrorMessage = this.error !== null
      }, 100)
    },
  },
}
</script>
