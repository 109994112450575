import auth from '../auth'
import api from '../api'

export default {
  userLogin,
  userAuth,
  userVerifyCode,
}

function userAuth(input) {
  const headers = {
    'Content-Type': 'application/json',
    // 'device-info': 'web',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    // 'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
    // 'Access-Control-Allow-Credentials': true,
    // Referer: '127.0.0.1',
  }

  return auth.sendRequestAuthenticateForm(api.auth, input, headers)
}

function userLogin(input) {
  const headers = {
    'Content-Type': 'application/json',
  }

  return auth.sendRequestForm(api.login, input, headers)
}

function userVerifyCode(input) {
  const headers = {
    'Content-Type': 'application/json',
  }

  return auth.sendRequestForm(api.verifycode, input, headers)
}
