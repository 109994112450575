import cnst from '@/const/const'
import router from '@/router'
import moment from 'moment'

export const formatErrorMessages = (translationParent, msg) => {
  const errorArray = []
  // Check for error msgs
  if (msg !== null) {
    const json = JSON.parse(JSON.stringify(msg))
    // If error message is an array, then we have mutiple errors
    if (Array.isArray(json)) {
      json.map(() => {
        errorArray.push(msg)
      })
    } else {
      // Single error
      errorArray.push(msg)
    }
    return errorArray
  }
  // all good, return null
  return null
}

export const handleError = (error, commit, reject) => {
  let errMsg = ''
  // Resets errors in store
  commit(cnst.SHOW_LOADING, false, { root: true })
  commit(cnst.ERROR, null, { root: true })

  // Checks if unauthorized
  if (error.response == undefined || error.response.status === 403 || error.response.status === 401) {
    window.localStorage.removeItem(cnst.STORAGE_DEVICE_TOKEN)
    window.localStorage.removeItem(cnst.STORAGE_EMAIL)
    router.push({
      path: '/login',
    })
    window.location.reload()
  } else if (error.response.status === 406) {
    let refreshPage = () => {
      window.location.reload()
    }
    errMsg = error.response.data.message

    commit(cnst.ERROR_CALLBACK, refreshPage, { root: true })
    commit(cnst.ERROR, errMsg, { root: true })
  } else {
    console.log(error.response)
    console.log(error.response.data)

    if (error.response) {
      if (typeof error.response.data.message !== 'undefined') {
        errMsg = error.response.data.message
      } else {
        errMsg = 'SERVER_TIMEOUT_CONNECTION_ERROR'
      }
    } else {
      errMsg = 'SERVER_TIMEOUT_CONNECTION_ERROR'
    }

    setTimeout(() => {
      return errMsg ? commit(cnst.ERROR, errMsg, { root: true }) : commit(cnst.SHOW_ERROR, false, { root: true })
    }, 0)
  }
  reject(error)
}

export const buildSuccess = (msg, commit, resolve, resolveParam = undefined) => {
  commit(cnst.SHOW_LOADING, false, { root: true })
  // commit(cnst.SUCCESS, null, { root: true })
  setTimeout(() => {
    // return msg ? commit(cnst.SUCCESS, msg, { root: true }) : commit(cnst.SHOW_SUCCESS, false, { root: true })
  }, 0)
  // commit(cnst.ERROR, null, { root: true })
  resolve(resolveParam)
}

export const setTenantStore = (value) => {
  if (value !== null) {
    const json = JSON.stringify(value)
    localStorage.setItem(cnst.STORAGE_SELECT_TENANT, json)
    return
  }
  localStorage.setItem(cnst.STORAGE_SELECT_TENANT, null)
}

export const getTenantStore = () => {
  if (localStorage.getItem(cnst.STORAGE_SELECT_TENANT)) {
    return JSON.parse(localStorage.getItem(cnst.STORAGE_SELECT_TENANT))
  }
  return null
}

export const convertDateTimeUTC = (value, format = 'YYYY/MM/DD HH:mm') => {
  if (value !== null) {
    return moment(value).format(format)
  }
  return null
}

export const formatDateTime = (value) => {
  if (value) {
    return moment(String(value)).format('YYYYMMDD')
  }
  return ''
}
