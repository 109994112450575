import cnst from '@/const/const'
import router from '@/router'
import { handleError } from '@/utils/utils.js'
import api from '@/api/services/login'

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  isTokenSet: (state) => state.isTokenSet,
}

const actions = {
  authenticate({ commit }, input) {
    return new Promise((resolve, reject) => {
      // commit(cnst.SHOW_LOADING, true, { root: true })
      api
        .userAuth(input)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          if (error.response.status != 406) {
            error.response.data.message == 'Login failed, please try again.'
            error.response.status = 404
          }
          handleError(error, commit, reject)
        })
    })
  },
  userLogin({ commit }, input) {
    return new Promise((resolve, reject) => {
      // commit(cnst.SHOW_LOADING, true, { root: true })
      api
        .userLogin(input)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          if (error.response.status !== 406) {
            error.response.data.message = 'Login failed, please try again.'
            error.response.status = 404
          }
          handleError(error, commit, reject)
        })
    })
  },
  userLogout({ commit }) {
    commit(cnst.LOGOUT)
    window.localStorage.removeItem(cnst.STORAGE_DEVICE_TOKEN)
    window.localStorage.removeItem(cnst.STORAGE_EMAIL)
    router.push({
      path: '/login',
    })
    // store.commit('SET_SELECT_TENANT', null)
    window.location.reload()
  },
  verifyCode({ commit }, input) {
    return new Promise((resolve, reject) => {
      // commit(cnst.SHOW_LOADING, true, { root: true })
      api
        .userVerifyCode(input)
        .then((response) => {
          if (response.status === 200) {
            window.localStorage.setItem(
              cnst.STORAGE_AUTHTOKEN,
              response.data.auth_token
              // response.headers.idtoken,
            )
            window.localStorage.setItem(cnst.STORAGE_EMAIL, response.data.mail)
            // buildSuccess(
            //     null,
            //     commit,
            //     resolve,
            //     router.push({
            //       path: '/stamp',
            //     }),
            //   )
            //
            resolve(response)
          }
        })
        .catch((error) => {
          if (error.response.status !== 406) {
            error.response.data.message = 'Verify Code failed, please try again.'
            error.response.status = 404
          }
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [cnst.SAVE_TOKEN](state, token) {
    state.token = token
    state.isTokenSet = true
  },
  [cnst.LOGOUT](state) {
    state.user = null
    state.token = null
    state.isTokenSet = false
  },
  [cnst.SAVE_USER](state, user) {
    state.user = user
  },
}

const state = {
  user: null,
  token: JSON.parse(!!localStorage.getItem(cnst.STORAGE_AUTHTOKEN)) || null,
  isTokenSet: !!localStorage.getItem(cnst.STORAGE_AUTHTOKEN),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
