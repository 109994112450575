<template>
  <v-dialog v-model="show" persistent max-width="300px">
    <!-- When there is an confirm -->
    <v-card v-show="typeConfirm">
      <v-card-title class="primary text-h3 py-3" primary-title>
        {{ $t(dialogTitle) }}
      </v-card-title>

      <v-card-text class="text-md-center">
        {{ $t(dialogMessage) }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="show = false">
          {{ $t('button.Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="pushOK">
          {{ $t('button.OK') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- When there is an error -->
    <v-card v-show="typeError">
      <v-card-title class="error lighten-2 py-2 text-h4" primary-title>
        {{ $t('errors.ERROR') }}
      </v-card-title>

      <v-card-text class="text-md-center">
        {{ $t(dialogMessage) }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="error lighten-2" @click="show = false">
          {{ $t('button.OK') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogMessage',
  props: {
    value: Boolean,
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    typeConfirm: Boolean,
    typeError: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    dialogMessage: {
      get() {
        return this.message
      },
    },
    dialogTitle: {
      get() {
        return this.title
      },
    },
  },
  methods: {
    pushOK() {
      this.show = false
      this.$emit('click', true)
    },
  },
}
</script>
