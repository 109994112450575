<template>
  <v-dialog v-model="showSuccessMessage" max-width="300px" class="py-3" persistent>
    <v-card v-show="showSuccessMessage">
      <v-card-text class="text-md-center">
        <!-- {{ successMessage }} -->
        <p v-html="successMessage" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" small @click="showSuccessMessage = false">
          {{ $t('button.OK') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cnst from '@/const/const'

export default {
  name: 'SuccessMessage',
  computed: {
    showSuccessMessage: {
      get() {
        return this.$store.state.success.showSuccessMessage
      },
      set(value) {
        this.$store.commit(cnst.SHOW_SUCCESS, value)
      },
    },
    successMessage() {
      if (this.$store.state.success.successMessageParams) {
        return this.$i18n.t(this.$store.state.success.successMessage, [...this.$store.state.success.successMessageParams])
      }
      return this.$i18n.t(this.$store.state.success.successMessage)
    },
    successMessageTimeout() {
      return this.$store.state.success.successMessageTimeout
    },
  },
  watch: {
    successMessage() {
      setTimeout(() => {
        this.showSuccessMessage = this.successMessage !== ''
      }, 100)
    },
  },
}
</script>
