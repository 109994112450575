// const apiURL = 'api'// 'https://5crednqqdk.execute-api.ap-northeast-1.amazonaws.com/'

// API
// Dev
// const apiURL = 'https://z6vxi5bodl.execute-api.ap-northeast-1.amazonaws.com'

// Main
const apiURL = 'https://wf5lfe9pu4.execute-api.ap-northeast-1.amazonaws.com'

// Auth
//VN
// const apiAuth = 'http://192.168.11.138:5000'

//NB 技術戦略室
const apiAuth = 'https://pm7xcdojcl.execute-api.ap-northeast-1.amazonaws.com/prod'

// Stage
// Dev
// const stage = 'Dev'
// const stage = 'Smile'

// Main
// const stage = 'Staging'
const stage = 'Prod_ver200'

/* eslint-disable no-unused-vars */

// Notification
// Dev
// const URL = 'https://ohayo-test.cubesystem.work/'

// Main
const URL = 'https://ohayo.cubesystem.work/'

/* eslint-enable no-unused-vars */

export default {
  pushnote: URL,
  login: `${apiURL}/${stage}/signin`, // Login
  auth: `${apiAuth}/authenticate`,
  verifycode: `${apiURL}/${stage}/verifycode`,
  sendStamp: `${apiURL}/${stage}/stamp`,
  getStampByID: `${apiURL}/${stage}/getstampbyid`,
  getTotalStamp: `${apiURL}/${stage}/gettotalstamp`,
  lastestVersion: 'https://d2gohjiy9ys2di.cloudfront.net/smile_portal_lastest_version.json',
  alive: `${apiURL}/${stage}/alivestamping`,
  getSteps: `${apiURL}/${stage}/steps`,
  getTotalCompanySteps: `${apiURL}/${stage}/companysteps`,
  getRuleGacha: `${apiURL}/${stage}/rulegacha`,
  sendGacha: `${apiURL}/${stage}/sendgacha`,
  sendCoin: `${apiURL}/${stage}/participantcoin`,
}
