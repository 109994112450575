import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import '@/plugins/common'
// import  firebase from '@firebase/app';
// import '@firebase/messaging';
import firebaseMessaging from './firebase'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.prototype.$messaging = firebaseMessaging
Vue.config.productionTip = false

/*Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBbLrESmEvrUP1rxgh8uOC4rXoc05rbmRg',
    libraries: 'places',
  },
  installComponents: true,
})*/

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
