<template>
  <v-menu v-model="dataMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field :label="dataLabel" prepend-icon="mdi-calendar" readonly :rules="[checkDate]" :value="dataValue" v-on="on" />
    </template>
    <v-date-picker v-model="dataValue" locale="en-in" no-title @input="dataMenu = false" />
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ItemDatePicker',
  props: {
    datevalue: {
      type: Date,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    default: {
      type: Boolean,
      default: false,
    },
    option: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      dataValue: this.datevalue,
      dataMenu: false,
      dataLabel: this.label,
      dataRules: this.required,
    }
  },
  computed: {
    checkDate() {
      if (this.dataRules) return (v) => !!v || 'This field is required'
      return true
    },
  },
  watch: {
    dataValue(value) {
      this.$emit('input', value)
    },
    label(value) {
      this.dataLabel = value
    },
  },
  methods: {
    clear() {
      this.dataValue = null
    },
    setDefault() {
      this.dataValue = moment(Date()).format('YYYY-MM-DD')
    },
  },
  created() {
    if (this.default) this.setDefault()
    if (this.option === '-1') this.dataValue = moment().subtract(1, 'days').format('YYYY-MM-DD')
    if (this.option === '-7') this.dataValue = moment().subtract(7, 'days').format('YYYY-MM-DD')
  },
}
</script>
