import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import cnst from '@/const/const'

var firebaseConfig = {
  apiKey: cnst.API_KEY,
  authDomain: cnst.AUTH_DOMAIN,
  projectId: cnst.PROJECT_ID,
  storageBucket: cnst.STORAGE_BUCKET,
  messagingSenderId: cnst.MESSAGING_SENDER_ID,
  appId: cnst.APP_ID,
  measurementId: cnst.MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)

export default firebase
