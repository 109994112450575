import Vue from 'vue'
import VueRouter from 'vue-router'
import children from '@/router/children'
import axios from 'axios'
import api from '@/api/api'
import cnst from '@/const/const'
import { store } from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Index'),
    children: [...children],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      reload: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  checkValidVersion()

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  // const isTokenSet = store.getters.isTokenSet
  const isTokenSet = !!localStorage.getItem(cnst.STORAGE_AUTHTOKEN)
  const isMailset = !!localStorage.getItem(cnst.STORAGE_EMAIL)
  if (to.path === '/' && isTokenSet && isMailset) {
    return next('/home')
  } else if (to.name === 'Login' && isTokenSet && isMailset) {
    return next('/home')
  } else if (requiresAuth && (!isTokenSet || !isMailset)) {
    return next('/login')
  } else {
    return next()
  }
})

async function checkValidVersion() {
  await axios
    .get(api.lastestVersion)
    .then((response) => {
      if (response.data.version !== cnst.VERSION) {
        let refreshPage = () => {
          Chache.delete('@/*')
          window.location.reload(true)
        }
        let errMsg = cnst.MSG_UPDATE_NEW_VERSION
        handleInvalidVersion(errMsg, refreshPage)
      }
    })
    .catch((error) => {
      console.log(error)
      let errMsg = 'Check version failed.'
      handleInvalidVersion(errMsg, null)
    })
}

function handleInvalidVersion(errMsg, callback) {
  store.commit(cnst.SHOW_LOADING, false, { root: true })

  store.commit(cnst.ERROR_CALLBACK, callback, { root: true })
  store.commit(cnst.ERROR, errMsg, { root: true })
}

export default router
