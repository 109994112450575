<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { provide } from 'vue'
import firebaseMessaging from './firebase'
export default {
  name: 'App',
  //options api
  provide: {
    messaging: firebaseMessaging,
  },
  //composition api
  setup() {
    provide('messaging', firebaseMessaging)
  },
  data: () => ({
    //
  }),
  mounted() {
    if (firebaseMessaging.messaging.isSupported()) {
      firebaseMessaging
        .messaging()
        .requestPermission()
        .then(() => {
          console.log('Notification permission granted.')
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err)
        })
    }
  },
}
</script>
<style>
@font-face {
  font-family: 'YuzuPop';
  src: local('YuzuPop'), url(./fonts/YuzuPop/YuzuPop-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'RiiPop';
  src: local('RiiPop'), url(./fonts/RiiPop/RiiPopkkR.otf) format('truetype');
}
</style>
