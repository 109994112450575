import auth from '../auth'
import api from '../api'

export default {
  sendStamp,
  getStamp,
  alive,
}
function sendStamp(input) {
  const headers = {
    'Content-Type': 'application/json',
  }

  return auth.sendRequestForm(api.sendStamp, input, headers)
}

function getStamp(input) {
  return auth.sendRequestParams('GET', api.sendStamp, input)
}

function alive(input) {
  const headers = {
    'Content-Type': 'application/json',
  }

  return auth.sendRequestForm(api.alive, input, headers)
}
