import axios from 'axios'
import moment from 'moment'
// import cnst from '@/const/const'
// import api from '@/api/api'

const AUTH_TOKEN_KEY = 'authToken'
//const X_API_KEY = 'bG5upfTZlY5nMU3PosLwi5Zz1NXIjOX72CU7T9aN' // Inopay
const X_API_KEY = 'tNciWrD6PF11jLQy0krFWapdryPfB4i981SEMiRY' // Main

const X_API_KEY_AUTH = 'ApiDNO8r6D7buTm2NB8pC14r3U7wuLIq1bquU5jT'

axios.defaults.baseURL = process.env.APIURL
// axios.defaults.baseURL = 'http://192.168.11.127:1323'
// axios.defaults.withCredentials = true
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (err.response) {
      // const { status, data } = err.response
    }
    return Promise.reject(err)
  }
)

export default {
  sendRequest,
  setAuthToken,
  getAuthToken,
  clearAuthToken,
  isLoggedIn,
  sendRequestForm,
  sendRequestParams,
  formatDateTime,
  formatPhoneNumber,
  sendRequestAuthenticateForm,
}

async function sendRequestForm(url, requestData, inputHeader = {}) {
  // let response = await checkValidVersion()
  // if (response.status === 406) throw response

  var headers = {
    ...inputHeader,
    'X-API-Key': X_API_KEY,
    Authorization: localStorage.getItem('authtoken'),
  }

  return axios.post(url, requestData, { headers })
}

async function sendRequestAuthenticateForm(url, requestData, inputHeader = {}) {
  // let response = await checkValidVersion()
  // if (response.status === 406) throw response

  var headers = {
    ...inputHeader,
    'X-API-Key': X_API_KEY_AUTH,
    Authorization: localStorage.getItem('authtoken'),
  }

  return axios.post(url, requestData, { headers })
}

async function sendRequest(method, url, requestData, inputHeader = {}) {
  // let response = await checkValidVersion()
  // if (response.status === 406) throw response

  var headers = {
    ...inputHeader,
    'X-API-Key': X_API_KEY,
    Authorization: localStorage.getItem('authtoken'),
  }
  const requestOptions = {
    url,
    method,
    data: requestData,
    headers,
  }

  return axios.request(requestOptions)
}

async function sendRequestParams(method, url, requestData, inputHeader = {}) {
  // let response = await checkValidVersion()
  // if (response.status === 406) throw response

  var headers = {
    ...inputHeader,
    'X-API-Key': X_API_KEY,
    Authorization: localStorage.getItem('authtoken'),
  }
  const requestOptions = {
    method,
    url,
    params: requestData,
    headers,
  }
  return axios.request(requestOptions)
}

function setAuthToken(token) {
  localStorage.setItem(AUTH_TOKEN_KEY, token)
}

function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

function clearAuthToken() {
  document.cookie = 'CXSESSIONID=; Path=/; HttpOnly; Secure'
  localStorage.clear()
}

function isLoggedIn() {
  const authToken = getAuthToken()
  if (authToken) {
    if (authToken.employeeId === '') {
      return false
    }
    return true
  }
  return false
  // return !!authToken && !isTokenExpired(authToken)
}

function formatDateTime(value, strFormat = 'YYYYMMDDhhmmss') {
  if (value) {
    return moment(String(value)).format(strFormat)
  }
}

function formatPhoneNumber(value) {
  var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,3})/)
  return !x[2] ? x[1] : x[1] + '-' + x[2] + '-' + x[3]
}

// async function checkValidVersion () {
//     return new Promise((resolve, reject) => {
//         axios.get(api.lastestVersion).then((response) => {
//             if (response.data.version !== cnst.VERSION) {
//                 response.status = 406

//                 Object.defineProperty(response, 'response', {
//                     value: {
//                         data: {
//                             message: "VERSION NOT MATCH"
//                         },
//                         status: 406
//                     },
//                     writable: false
//                   })
//             }
//             resolve(response)
//         }).catch((error) => {
//             console.log(typeof(error))
//             console.log(error)
//             reject(error)
//         })
//     })
// }
