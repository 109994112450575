export default {
  VERSION: '2.0.4',

  STORAGE_AUTHTOKEN: 'authtoken',
  STORAGE_EMAIL: 'mail',
  STORAGE_DEVICE_TOKEN: 'device_token',
  STORAGE_SELECT_TENANT: 'STORAGE_SELECT_TENANT',

  ID_STAMP_1: '1',
  ID_STAMP_2: '2',
  ID_STAMP_3: '3',
  ID_STAMP_4: '4',

  MSG_UPDATE_NEW_VERSION: '新しい バージョンがあります。<br />アプリを更新します。',

  //firebase
  API_KEY: 'AIzaSyChLf2PP_iOZLOOLbfKCR2AFmiAIJtEQKc',
  AUTH_DOMAIN: 'prod-smileshareportal.firebaseapp.com',
  PROJECT_ID: 'prod-smileshareportal',
  STORAGE_BUCKET: 'prod-smileshareportal.appspot.com',
  MESSAGING_SENDER_ID: '834196451663',
  APP_ID: '1:834196451663:web:a4027d9e0bc3ae48bd953a',
  MEASUREMENT_ID: 'G-6YD2HGM9DK',

  // app
  SET_APP_VERSION: 'SET_APP_VERSION',
  // locale
  SET_LOCALE: 'SET_LOCALE',
  // error
  SHOW_ERROR: 'SHOW_ERROR',
  ERROR: 'ERROR',
  ERROR_CALLBACK: 'ERROR_CALLBACK',
  // success
  SHOW_SUCCESS: 'SHOW_SUCCESS',
  SUCCESS: 'SUCCESS',
  // loading
  SHOW_LOADING: 'SHOW_LOADING',
  // auth
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  SAVE_TOKEN: 'SAVE_TOKEN',
  SAVE_USER: 'SAVE_USER',
  LOGOUT: 'LOGOUT',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  RESET_EMAIL_SENT: 'RESET_EMAIL_SENT',
  SHOW_CHANGE_PASSWORD_INPUTS: 'SHOW_CHANGE_PASSWORD_INPUTS',
  // home
  FILL_STAMP: 'LIST_ALL_STAMP',
  FILL_STAMP_DATA: 'LIST_STAMP_DATA',
  FILL_DETAIL: 'LIST_DETAIL',
  FILL_TAG: 'LIST_TAG',
  TOTAL_STAMP: 'TOTAL_STAMP',
  TOTAL_COMPANY_STEPS: 'TOTAL_COMPANY_STEPS',
  // steps
  GET_STEPS: 'GET_STEPS',
  GET_TOTAL_STEPS: 'GET_TOTAL_STEPS',
  GET_MAPS: 'GET_MAPS',
  GET_FLAG_RECEIVE_COIN: 'GET_FLAG_RECEIVE_COIN',
}
