import Vue from 'vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'
import SuccessMessage from '@/components/common/SuccessMessage.vue'
import ItemDatePicker from '@/components/common/ItemDatePicker.vue'
import DialogMessage from '@/components/common/DialogMessage.vue'

Vue.component('ErrorMessage', ErrorMessage)
Vue.component('SuccessMessage', SuccessMessage)
Vue.component('ItemDatePicker', ItemDatePicker)
Vue.component('DialogMessage', DialogMessage)
