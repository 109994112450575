import api from '@/api/services/step'
import { buildSuccess, handleError } from '@/utils/utils.js'
import cnst from '@/const/const'

const getters = {
  getMap: (state) =>
    state.maps.map((item) => {
      return {
        lat: Number(item.latitude),
        lng: Number(item.longitude),
      }
    }),
}

const actions = {
  getSteps({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .getSteps(input)
        .then((response) => {
          if (response.status === 200) {
            commit(cnst.GET_STEPS, response.data.data)
            commit(cnst.GET_TOTAL_STEPS, response.data.total_steps)
            commit(cnst.GET_MAPS, response.data.maps)
            commit(cnst.GET_FLAG_RECEIVE_COIN, response.data.flag_receive_coin)
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          error.response.data.message = ''
          handleError(error, commit, reject)
        })
    })
  },
  sendCoin({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .sendCoin(input)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          error.response.data.message = ''
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [cnst.GET_STEPS](state, stepsList) {
    state.stepsList = stepsList
  },
  [cnst.GET_TOTAL_STEPS](state, totalSteps) {
    state.totalSteps = totalSteps
  },
  [cnst.GET_MAPS](state, maps) {
    state.maps = maps
  },
  [cnst.GET_FLAG_RECEIVE_COIN](state, flag_receive_coin) {
    state.flag_receive_coin = flag_receive_coin
  },
}

const state = {
  stepsList: [],
  totalSteps: 0,
  maps: [],
  flag_receive_coin: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
