import auth from '../auth'
import api from '../api'

export default {
  getStampByID,
  getTotalStamp,
  getTotalCompanySteps,
  getRuleGacha,
  sendGacha,
  getGachaNotiByMail,
}

function getStampByID(input) {
  return auth.sendRequestParams('GET', api.getStampByID, input)
}

function getTotalStamp(input) {
  return auth.sendRequestParams('GET', api.getTotalStamp, input)
}

function getTotalCompanySteps(input) {
  return auth.sendRequestParams('GET', api.getTotalCompanySteps, input)
}
function sendGacha(input) {
  const headers = {
    'Content-Type': 'application/json',
  }

  return auth.sendRequestForm(api.sendGacha, input, headers)
}
function getRuleGacha(input) {
  return auth.sendRequestParams('GET', api.getRuleGacha, input)
}

function getGachaNotiByMail(input) {
  return auth.sendRequestParams('GET', api.sendGacha, input)
}
