import api from '@/api/services/home'
import { buildSuccess, handleError } from '@/utils/utils.js'
import cnst from '@/const/const'

const getters = {}
const actions = {
  getStampById({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .getStampByID(input)
        .then((response) => {
          if (response.status === 200) {
            commit(cnst.FILL_STAMP_DATA, response.data.data)
            commit(cnst.FILL_STAMP, response.data.stamps)
            console.log(response.data)
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getTotalStamp({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .getTotalStamp(input)
        .then((response) => {
          if (response.status === 200) {
            commit(cnst.TOTAL_STAMP, response.data.total_company_stamp)
            commit(cnst.FILL_DETAIL, response.data.details)
            commit(cnst.FILL_TAG, response.data.tags)
            console.log(response.data)
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getTotalCompanySteps({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .getTotalCompanySteps(input)
        .then((response) => {
          if (response.status === 200) {
            commit(cnst.TOTAL_COMPANY_STEPS, response.data)
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getRuleGacha({ commit }, input) {
    return new Promise((resolve, reject) => {
      api
        .getRuleGacha(input)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          error.response.data.message = 'ガチャを引くことができません'
          handleError(error, commit, reject)
        })
    })
  },
  sendGacha({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .sendGacha(input)
        .then((response) => {
          if (response.status === 202) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          error.response.data.message = 'ガチャを引くことができません'
          handleError(error, commit, reject)
        })
    })
  },
  getGachaNotiByMail({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .getGachaNotiByMail(input)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [cnst.FILL_STAMP](state, listStamps) {
    state.listStamps = listStamps
  },
  [cnst.FILL_STAMP_DATA](state, listStampData) {
    state.listStampData = listStampData
  },
  [cnst.FILL_DETAIL](state, listDetail) {
    state.listDetail = listDetail
  },
  [cnst.FILL_TAG](state, listTag) {
    state.listTag = listTag
  },
  [cnst.TOTAL_STAMP](state, totalStamp) {
    state.totalCompanyStamp = totalStamp
  },
  [cnst.TOTAL_COMPANY_STEPS](state, totalSteps) {
    state.totalCompanySteps = totalSteps
  },
}

const state = {
  listStampData: [],
  listStamps: [],
  listDetail: [],
  listTag: [],
  totalCompanyStamp: 0,
  totalCompanySteps: {},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
