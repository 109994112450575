import auth from '../auth'
import api from '../api'

export default {
  getSteps,
  sendCoin,
}

function getSteps(input) {
  return auth.sendRequestParams('GET', api.getSteps, input)
}
function sendCoin(input) {
  const headers = {
    'Content-Type': 'application/json',
  }
  return auth.sendRequestForm(api.sendCoin, input, headers)
}
