import api from '@/api/services/stamp'
import { buildSuccess, handleError } from '@/utils/utils.js'
import cnst from '@/const/const'

const getters = {}
const actions = {
  sendStamp({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .sendStamp(input)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          if (error.response.status !== 406) {
            error.response.data.message = '送金処理がエラーになりました。管理者へお問い合わせください。'
          }
          handleError(error, commit, reject)
        })
    })
  },
  getStamp({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .getStamp(input)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  alive({ commit }, input) {
    commit(cnst.SHOW_LOADING, true, { root: true })
    return new Promise((resolve, reject) => {
      api
        .alive(input)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(null, commit, resolve, response)
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {}

const state = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
